var exports = {};
exports = {
  A: {
    A: {
      "1": "A B",
      "2": "J D E F 6B"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "1": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB 8B",
      "260": "0 1 2 3 4 5 6 7 8 M N O s t u v w x y z",
      "292": "I r J D E F A B C K L G 9B"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "33": "A B C K L G M N O s t u v w x y",
      "548": "I r J D E F"
    },
    E: {
      "1": "0B 1B 2B pB 3B JC KC",
      "2": "CC wB",
      "260": "D E F A B C K L G EC FC GC xB nB oB yB HC IC zB",
      "292": "J DC",
      "804": "I r"
    },
    F: {
      "1": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b oB",
      "2": "F B LC MC NC OC",
      "33": "C PC",
      "164": "nB 4B"
    },
    G: {
      "1": "0B 1B 2B pB 3B",
      "260": "E TC UC VC WC XC YC ZC aC bC cC dC eC fC gC hC iC jC zB",
      "292": "RC SC",
      "804": "wB QC 5B"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H pC qC",
      "33": "I oC 5B",
      "548": "qB lC mC nC"
    },
    J: {
      "1": "A",
      "548": "D"
    },
    K: {
      "1": "d oB",
      "2": "A B",
      "33": "C",
      "164": "nB 4B"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "I sC tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "1": "6C"
    }
  },
  B: 4,
  C: "CSS Gradients"
};
export default exports;